<template>
  <div class="">
    <loading :active.sync="isLoading"></loading>

    <div class="">
      <h4 class="">DANH SÁCH THÔNG BÁO</h4>
    </div>

    <form @submit.prevent="getAnnouncement()">
      <div class="d-flex align-items-center my-7">
        <p class="mx-1">
          <input
              placeholder="Tìm kiếm theo nội dung thông báo"
              style="border: 1px solid #bebebe; border-radius: 4px; width: 270px"
              class="py-2 px-4"
              v-model="contentSearch"
          />
        </p>
        <p
            class="mx-1 d-flex align-items-center px-3 py-1"
            style="border: 1px solid #bebebe; border-radius: 4px;"
        >
          từ
          <input
              placeholder="Khoảng ngày hiển thị"
              type="date"
              style="width: 140px;"
              class="py-1 px-4 ml-1"
              v-model="fromDateSearch"
              :max="toDateSearch"
          />
          đến
          <input
              placeholder="Khoảng ngày hiển thị"
              type="date"
              style="width: 140px;"
              class="py-1 px-4 ml-1"
              v-model="toDateSearch"
              :min="fromDateSearch"
          />
        </p>
        <p class="mx-1">
          <select
              style="border: 1px solid #bebebe; border-radius: 4px; max-width: 200px; text-overflow: ellipsis; overflow: hidden"
              class="py-2 px-4"
              v-model="courseIdSearch"
          >
            <option :value="null" selected>Khoá học</option>
            <option
                v-for="(course, index) in listCourse"
                :value="course.id"
                :key="`course-slt-${index}`"
            >
              {{ course.name }}
            </option>
          </select>
        </p>
        <p class="mx-1">
          <select
              style="border: 1px solid #bebebe; border-radius: 4px"
              class="py-2 px-4"
              v-model="activeSearch"
          >
            <option :value="null" selected>Trạng thái</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </p>
        <p class="mx-1">
          <button class="" style="border-radius: 4px; border: 1px solid #bebebe; color: #00b0ff; padding: 7px 15px">
            Tìm kiếm
          </button>
        </p>
        <p class="mx-1" style="flex: 1; text-align: right">
          <button
              class=""
              style="border-radius: 4px; background: #89ADF2; color: #fff; padding: 7px 15px"
              @click="openCreatModal = true"
          >
            Tạo thông báo
          </button>
        </p>
      </div>
    </form>

    <div>
      <table style="width: 100%;">
        <tr>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center">
            STT
          </td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Nội dung thông báo</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Mức độ ưu tiên</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Loại thông báo</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Ngày bắt đầu</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Ngày kết thúc</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Tần suất hiển thị</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Trạng thái</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Áp dụng khóa học</td>
          <td style="padding: 18px 15px; background: #f0f0f0; height: 100%; text-align: center;">Action</td>
        </tr>

        <tr
            style="border-bottom: 1px solid #f0f0f0;"
            v-for="(announcement, index) in announcementPagination"
            :key="`announ-${index}`"
            class="table-list-announ"
        >
          <td style="padding: 8px 15px; vertical-align: center; text-align: center">
            {{ (page - 1) * perPage + index + 1 }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center; max-width: 200px">
            {{ announcement.content }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            {{ announcement.priority == 1 ? 'Low' : null }}
            {{ announcement.priority == 2 ? 'Medium' : null }}
            {{ announcement.priority == 3 ? 'High' : null }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            {{ announcement.type == 1 ? 'Information' : 'Warning' }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            {{ announcement.from_date }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            {{ announcement.to_date ? announcement.to_date : `Không xác định` }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            {{ announcement.always_show ? 'Luôn hiển thị' : '' }}
            {{ announcement.daily_frequency ? `${announcement.daily_frequency} Ngày 1 lần` : '' }}
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            <label
                v-if="announcement.active == 1"
                class="bg-success py-1 px-3 text-white rounded"
            >
              Active
            </label>
            <label
                v-if="announcement.active == 0"
                class="bg-danger py-1 px-3 text-white rounded"
            >
              Inactive
            </label>
          </td>
          <td style="padding: 8px 15px; vertical-align: center;">
            <div style="position: relative;" class="group-course-pop">
              {{ announcement.courses.length }} Khóa học

              <div class="course-pop"
                   style="position: absolute; z-index: 10; top: 100%; background: #fff; padding: 8px;">
                <p
                    v-for="(course, indexCourse) in announcement.courses"
                    :key="`course-Slted-${indexCourse}`"
                >
                  {{ course.name }}
                </p>
              </div>
            </div>
          </td>
          <td style="padding: 8px 15px; vertical-align: center; text-align: right">
            <div class="d-flex justify-content-end">
              <button
                  class="btn btn-icon btn-light-success btn-sm ml-2"
                  @click="announcementDetail = announcement"
              >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <v-icon color="">mdi-square-edit-outline</v-icon>
                  </span>
              </button>
              <button
                  class="btn btn-icon btn-light-danger btn-sm ml-2"
                  @click="deleteAnnouncement(announcement.id)"
              >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <v-icon color="">mdi-trash-can</v-icon>
                  </span>
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="d-flex mt-10 align-items-center">
      <p style="flex: 1">
        {{ (page - 1) * perPage + 1 }}
        -
        {{ page * perPage > listAnnouncement.length ? listAnnouncement.length : page * perPage }}
        from
        {{ listAnnouncement.length }}
      </p>

      <div class="d-flex align-items-center">
        <div style="margin-right: 20px; display: flex; align-items: center; position: relative;">
          Rows per page
          <select
              style="z-index: 10; position: relative; padding-right: 20px; margin-left: 15px;"
              v-model="perPage"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </select>
          <svg style="position: absolute; right: 0; z-index: 1" xmlns="http://www.w3.org/2000/svg" width="30"
               height="25" viewBox="0 0 30 25" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.35547 10.0449L15.3738 15.0674L21.3922 10.0449H9.35547Z"
                  fill="#8D8D8D"/>
          </svg>
        </div>
        <button
            style="padding: 5px 10px; border: none; background: none; font-size: 20px; font-weight: 600"
            @click="page > 1 && (page = page - 1)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary text-success">
            <v-icon color="">mdi-chevron-left</v-icon>
          </span>
        </button>
        <button
            style="padding: 5px 10px; border: none; background: none; font-size: 20px; font-weight: 600; margin-left: 2px"
            @click="page < listAnnouncement.length / perPage && (page = page + 1)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary text-success">
            <v-icon color="">mdi-chevron-right</v-icon>
          </span>
        </button>
      </div>
    </div>


    <!--  Modal ADD ANOUNCEMENT  -->
    <div
        v-if="openCreatModal"
        class=""
        style="display: flex; align-items: center; justify-content: center; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5);"
    >

      <div style="position: relative; max-height: 90vh">
        <button
            style="display: inline-flex; align-items: center; position: absolute; left: 100%; margin-left: 10px; top: 10px"
          @click="openCreatModal = false"
        >
          <span style="margin-right: 5px; background: #fff; border-radius: 50%; height: 24px; width: 24px; display: flex; align-items: center; justify-content: center;">
          X
          </span>
          <span style="color: white;">
            Đóng
          </span>
        </button>
        <div class=""
             style="position: relative; background: #fff; border-radius: 20px; padding: 20px; overflow: auto; max-height: 90vh">
          <h4 v-if="!announcementDetail">Tạo thông báo</h4>
          <h4 v-if="announcementDetail">Chi tiết thông báo</h4>
          <form @submit.prevent="submitAnnouncement()">
            <div class="mt-8 d-flex align-items-center">
              <label>Active thông báo</label>
              <div class="ml-10"
                   style="height: 33px; width: 70px; display: flex; align-items: center; border-radius: 50px; box-shadow: -1px -1px 0px rgba(0,0,0,0.1)"
                   :style="{background: toggleActive ? '#00b0ff' : '#ebebee'}"
                   @click="toggleActive = !toggleActive"
              >
                <div
                    class=""
                    style="height: 25px; width: 25px; border-radius: 50%; margin: 0px 3px"
                    :style="{'margin-left': toggleActive ? 'auto' : '3px', 'background': toggleActive ? 'white' : '#00b0ff'}"
                >
                </div>
              </div>
            </div>

            <div class="mt-8 d-flex align-items-center">
              <p class="mb-0">Mức độ ưu tiên hiển thị<strong style="color: red">*</strong></p>
              <div
                  style="margin-left: 30px; margin-right: 20px; display: inline-flex; align-items: center; position: relative;"
              >
                <select
                    style="z-index: 10; position: relative; padding: 7px 25px 7px 15px; border: 1px solid #bebebe; border-radius: 4px"
                    v-model="priorityLevel"
                >
                  <option value="1">Low</option>
                  <option value="2">Medium</option>
                  <option value="3">High</option>
                </select>
                <svg style="position: absolute; right: 5px; z-index: 1" xmlns="http://www.w3.org/2000/svg" width="30"
                     height="25" viewBox="0 0 30 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.35547 10.0449L15.3738 15.0674L21.3922 10.0449H9.35547Z"
                        fill="#8D8D8D"/>
                </svg>
              </div>
            </div>

            <p class="mt-8 mb-1">Loại thông báo<strong style="color: red">*</strong></p>
            <div class="d-flex">
              <p style="flex: 1; display: flex; align-items: center">
                <input v-model="typeNoti" value="1" id="type-noti-1" type="radio" name="type-noti">
                <label for="type-noti-1" style="margin-left: 5px; margin-top: 1px; margin-bottom: 0;">Infomation</label>
              </p>
              <p style="flex: 1; display: flex; align-items: center">
                <input v-model="typeNoti" value="2" id="type-noti-2" type="radio" name="type-noti">
                <label for="type-noti-2" style="margin-left: 5px; margin-top: 1px; margin-bottom: 0;">
                  Critical warning
                </label>
              </p>
            </div>

            <div class="mt-5 d-flex align-items-center">
              <p class="mb-0">Khoá học áp dụng<strong style="color: red">*</strong></p>
              <div class="" style="flex: 1">
                <div class="ml-10 px-5 py-3"
                     style="flex: 1; position: relative; border: 1px solid #bebebe; border-radius: 4px;"
                >
                  <div class="d-flex flex-wrap align-items-baseline" style="max-width: 600px">
                    <p v-if="courseApply.length <= 0" class="my-1" style="flex: 1; font-size: 12px; color: #757575;">
                      Chọn khóa học hiển thị
                    </p>

                    <div class="">
                      <div
                          v-for="(course, index) in courseApply || []"
                          :key="`course-apply-${index}`"
                          class="d-inline"
                      >
                        <div
                            v-if="listCourse.find(e => e.id == course)"
                            class="py-1 px-2"
                            style="background: #ebebeb; border-radius: 20px; margin: 2px; display: inline-flex; align-items: center;"
                        >
                          <small
                              style="flex: 1; display: block; max-width: 200px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                            {{ listCourse.find(e => e.id == course).name }}
                          </small>
                          <button
                              type="button"
                              style="margin-left: 5px; background: #bebebe; border-radius: 100%; height: 20px; width: 20px; display: flex; align-items: center; justify-content: center"
                              @click="removeCourse(course)"
                          >
                            X
                          </button>
                        </div>
                      </div>
                      <span style="flex: 1; text-align: right">
                    <button
                        type="button"
                        class="px-3 py-1 my-1 ml-2"
                        style="background: #00b0ff; color: white; border-radius: 4px"
                        @click.stop="showSelectCourse = true"
                    >
                      + add
                    </button>
                  </span>
                    </div>
                  </div>

                  <div
                      v-if="showSelectCourse"
                      class=""
                      style="position: absolute; top: 100%; left: 0; padding: 10px; background: #fff; border-radius: 4px; box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.1); border: 1px solid #ebebeb;"
                      @click.stop=""
                  >
                    <div class="" style="position: relative; display: flex; align-items: center">
                      <input
                          style="padding: 7px 15px 7px 35px; border: 1px solid #bebebe; border-radius: 4px; width: 100%"
                          v-model="courseNameSearchInDropdown"
                      >

                      <p style="position: absolute; left: 8px; margin: 0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path
                              d="M12.5 11.2549H11.71L11.43 10.9849C12.41 9.84488 13 8.36488 13 6.75488C13 3.16488 10.09 0.254883 6.5 0.254883C2.91 0.254883 0 3.16488 0 6.75488C0 10.3449 2.91 13.2549 6.5 13.2549C8.11 13.2549 9.59 12.6649 10.73 11.6849L11 11.9649V12.7549L16 17.7449L17.49 16.2549L12.5 11.2549ZM6.5 11.2549C4.01 11.2549 2 9.24488 2 6.75488C2 4.26488 4.01 2.25488 6.5 2.25488C8.99 2.25488 11 4.26488 11 6.75488C11 9.24488 8.99 11.2549 6.5 11.2549Z"
                              fill="#979797"/>
                        </svg>
                      </p>
                    </div>
                    <div style="overflow: auto; max-height: 200px">
                      <button
                          type="button"
                          style="padding: 6px 15px; display: block; text-align: left; border-top: 1px solid #ebebeb;"
                          :style="{'background': courseApply.includes(course.id) ? 'aqua': 'transparent'}"
                          v-for="(course, index) in listCourseInDropdown"
                          :key="`course-${index}`"
                          @click.stop="addCourse(course)"
                      >
                        {{ course.name }}
                      </button>
                    </div>
                  </div>
                </div>

                <p
                    v-if="isSubmit && (!courseApply || courseApply.length <= 0)"
                    style="color: red;"
                    class="ml-10"
                >
                  Không được để trống
                </p>
              </div>
            </div>

            <p class="mt-8 mb-1">Nội dung thông báo<strong style="color: red">*</strong></p>
            <textarea
                style="border-radius: 4px; border: 1px solid #bebebe; width: 100%"
                placeholder="Nhập nội dung thông báo (tối đa 300 ký tự)"
                rows="5"
                class="p-5"
                v-model="contentNoti"
                maxlength="300"
            >
          </textarea>

            <p v-if="isSubmit && !contentNoti" style="color: red;">
              Không được để trống
            </p>

            <div class="mt-8">
              <p class="mb-1">Link điều hướng (https://example.com)</p>
              <input
                  type="url"
                  placeholder="Hyperlink https://example.com"
                  style="padding: 8px 15px; border-radius: 4px; border: 1px solid #bebebe; width: 100%"
                  v-model.trim="urlNoti"
              />
              <p v-if="isSubmit && !validUrl" style="color: red;">
                Sai định dạng
              </p>
            </div>

            <div class="mt-8 d-flex align-items-baseline">
              <div class="" style="flex: 1">
                <p class="mb-1">Ngày bắt đầu hiển thị<strong style="color: red">*</strong></p>
                <input
                    type="date"
                    placeholder="Ngày bắt đầu hiển thị"
                    style="width: 100%; padding: 8px 15px; border-radius: 4px; border: 1px solid #bebebe;"
                    :max="toDate"
                    :min="!announcementDetail ? toDay : null"
                    v-model="fromDate"
                >
              </div>

              <div class="ml-5" style="flex: 1">
                <p class="mb-1">Ngày kết thúc hiển thị</p>
                <input
                    type="date"
                    placeholder="Ngày kết thúc hiển thị"
                    style="width: 100%; padding: 8px 15px; border-radius: 4px; border: 1px solid #bebebe;"
                    :min="fromDate"
                    v-model="toDate"
                >
                <p class="mb-0 mt-1" style="width: max-content; font-size: 11px">
                  <strong style="color: red">*</strong>Không nhập nếu không xác định ngày kết thúc hiển thị
                </p>
              </div>
            </div>

            <p class="mt-8 mb-1">Tần suất hiển thị<strong style="color: red">*</strong></p>
            <div class="d-flex">
              <p style="flex: 1; display: flex; align-items: center">
                <input
                    :checked="!frequency || frequency <= 0" :value="0" id="turn-1" type="radio" name="turn"
                    @change="(e) => frequency = e.target.value"
                />
                <label for="turn-1" style="margin-left: 5px; margin-bottom: 0;">Luôn hiển thị</label>
              </p>

              <p style="flex: 1; display: flex; align-items: center">
                <input
                    :checked="frequency > 0" value="1" id="turn-2" type="radio" name="turn"
                    @change="(e) => frequency = e.target.value"
                />
                <label for="turn-2">
                  <input
                      :disabled="frequency <= 0"
                      v-model="frequency"
                      type="number"
                      name="turn"
                      min="1"
                      placeholder="nhập số ngày"
                      style="margin-left: 5px; width: 100px; padding: 8px 15px; border-radius: 4px; border: 1px solid #bebebe;"
                      :style="{background: frequency <= 0 ? '#bebebe' : 'transparent', cursor: frequency <= 0 ? 'not-allowed' : 'normal'}"
                      @change="(e) => e.target.value < 0 && (frequency = 1)"
                  >
                  <span style="margin-left: 5px;">ngày một lần</span>
                </label>
              </p>
            </div>

            <p class="mt-8 d-flex align-items-center">
              <input v-model="closeBtn" id="close-btn-show" type="checkbox">
              <label for="close-btn-show" class="ml-2 mb-0">Không hiển thị icon tắt announcement bar</label>
            </p>

            <div class="pt-5" style="text-align: right">
              <button
                  type="button"
                  @click="openCreatModal = false"
                  style="border-radius: 4px; padding: 10px 15px; background: #bebebe; color: white"
              >
                {{ !announcementDetail ? `Cancel` : `Đóng` }}
              </button>
              <button type="submit" class="ml-5"
                      style="border-radius: 4px; padding: 10px 15px; background: #89ADF2; color: white">
                {{ !announcementDetail ? `Tạo thông báo` : `Cập nhật` }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--  End Modal ADD ANOUNCEMENT  -->

    <!--  Modal Confirm delete  -->
    <div
        v-if="cfmDel"
        class=""
        style="display: flex; align-items: center; justify-content: center; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5);"
    >
      <div class=""
           style="text-align: center; background: #fff; border-radius: 20px; padding: 20px 50px; overflow: auto; max-height: 90vh">
        <h4>Xác nhận xóa</h4>
        <p>Bạn chắc chắn muốn xóa</p>

        <button class="m-2 py-3 text-white px-5 bg-dark rounded-lg" @click="() => {cfmDel = null; return;}">
          Cancel
        </button>

        <button class="m-2 py-3 text-white px-5 bg-danger text-white rounded-lg" @click="cfmDel(true)">
          Xóa
        </button>
      </div>
    </div>
    <!--  End Modal Confirm delete  -->

    <!--  Modal Complete stt  -->
    <div
        v-if="sttComplete == 1"
        class=""
        style="display: flex; z-index: 200; align-items: center; justify-content: center; position: fixed; top: 20px; right: 20px"
    >
      <div
          class="d-flex align-items-center"
          style="background: #fff; border-radius: 20px; padding: 20px; overflow: auto; max-height: 90vh; text-align: center;"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary text-success">
          <v-icon color="">mdi-clipboard-check-outline</v-icon>
        </span>
        <h4 class="ml-2 text-success" style="font-size: 16px; font-weight: 600;">Tác vụ thành công</h4>
      </div>
    </div>
    <!--  End Modal Complete stt  -->

    <!--  Modal Error stt  -->
    <div
        v-if="sttComplete == 2"
        class=""
        style="display: flex; z-index: 200; align-items: center; justify-content: center; position: fixed; top: 20px; right: 20px"
    >
      <div
          class="d-flex align-items-center"
          style="background: #fff; border-radius: 20px; padding: 20px; overflow: auto; max-height: 90vh; text-align: center;"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary text-success">
          <v-icon color="">mdi-alert-outline</v-icon>
        </span>
        <h4 class="ml-2 text-danger" style="font-size: 16px; font-weight: 600;">Tác vụ thất bại</h4>
      </div>
    </div>
    <!--  End Modal Error stt  -->
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";

export default {
  name: "AnnouncementList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      isLoading: false,

      listAnnouncement: [],

      listCourse: [],
      showSelectCourse: false,
      courseNameSearchInDropdown: null,

      page: 1,
      perPage: 10,

      contentSearch: null,
      fromDateSearch: null,
      toDateSearch: null,
      courseIdSearch: null,
      activeSearch: null,

      openCreatModal: false,
      toggleActive: false,
      priorityLevel: 1,
      typeNoti: 1,
      courseApply: [],
      contentNoti: null,
      fromDate: null,
      toDate: '',
      frequency: 1,
      closeBtn: false,
      urlNoti: '',
      validUrl: true,
      isSubmit: false,
      announcementDetail: null,
      cfmDel: null,
      sttComplete: false,

      toDay: new Date().toISOString().split('T')[0],
    };
  },
  computed: {
    announcementPagination() {
      return this.listAnnouncement.filter((e, index) => index >= (this.page - 1) * this.perPage && index < this.page * this.perPage);
    },
    listCourseInDropdown() {
      return this.listCourse.filter((e) => e.name.toLowerCase().includes((this.courseNameSearchInDropdown || '').toLowerCase()));
    },
    calcFromDate() {
      if (this.announcementDetail) {
        return new Date(this.announcementDetail.from_date).toISOString().split('T')[0];
      }
      return new Date().toISOString().split('T')[0];
    },
    calcToDate() {
      if (this.announcementDetail && this.announcementDetail.from_date) {
        return new Date(this.announcementDetail.to_date).toISOString().split('T')[0];
      }
      return null;
    },
  },
  async mounted() {
    document.addEventListener('click', () => {
      this.showSelectCourse = false
    });

    this.fromDate = this.calcFromDate;
    this.toDate = this.calcToDate;

    try {
      this.isLoading = true;
      let url = `prep-app/announcement-bar/courses`;
      let resp = await ApiService.get(url);
      this.listCourse = resp.data;
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false;
    }

    await this.getAnnouncement();
  },
  watch: {
    'showSelectCourse': function () {
      this.courseNameSearchInDropdown = null;
    },
    'perPage': function () {
      this.page = 1;
    },
    'announcementDetail': function () {
      if (this.announcementDetail != null) {
        this.openCreatModal = true;
      }
    },
    'openCreatModal': function () {
      this.isSubmit = false;
      if (!this.openCreatModal) {
        this.announcementDetail = null;
      }
      this.initForm();
    },
    'sttComplete': function () {
      if (this.sttComplete) {
        setTimeout(() => {
          this.sttComplete = false;
        }, 1500);
      }
    },
    'urlNoti': function () {
      this.validUrl = this.urlNoti ? new RegExp('^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$',
          'i'
      ).test(this.urlNoti) : true;
    },
  },
  methods: {
    initForm() {
      this.fromDate = this.calcFromDate;
      this.toDate = this.calcToDate;

      this.toggleActive = this.announcementDetail?.active || false;
      this.priorityLevel = this.announcementDetail?.priority || 1;
      this.typeNoti = this.announcementDetail?.type || 1;
      this.courseApply = (this.announcementDetail?.courses || []).map(e => e.id);
      this.contentNoti = this.announcementDetail?.content || null;
      this.frequency = this.announcementDetail?.always_show ? 0 : this.announcementDetail?.daily_frequency || 0;
      this.closeBtn = this.announcementDetail?.can_close || false;
      this.urlNoti = this.announcementDetail?.url || null;
    },
    async getAnnouncement() {
      this.isLoading = true;
      try {
        let url = `prep-app/announcement-bar?content=${this.contentSearch || ''}`;

        if (this.courseIdSearch) {
          url = `${url}&course_id=${this.courseIdSearch}`;
        }
        if (this.activeSearch != null) {
          url = `${url}&active=${this.activeSearch}`;
        }
        if (this.fromDateSearch) {
          url = `${url}&from_date=${this.fromDateSearch}`;
        }
        if (this.toDateSearch) {
          url = `${url}&to_date=${this.toDateSearch}`;
        }

        let resp = await ApiService.get(url);
        this.listAnnouncement = resp.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    addCourse(course) {
      let checkHasCourse = this.courseApply.includes(course.id);
      !checkHasCourse && (this.courseApply = [...this.courseApply, course.id]);
    },
    removeCourse(id) {
      this.courseApply = this.courseApply.filter(e => e != id);
    },
    async submitAnnouncement() {
      this.isSubmit = true;

      if (!this.contentNoti || !this.courseApply || !this.validUrl) {
        return;
      }

      let params = {
        title: 'No__Title',
        content: this.contentNoti,
        from_date: this.fromDate,
        to_date: this.toDate || undefined,
        always_show: !this.frequency && true,
        can_close: this.closeBtn,
        active: this.toggleActive,
        type: this.typeNoti,
        daily_frequency: this.frequency ? this.frequency : undefined,
        url: this.urlNoti || undefined,
        course_ids: this.courseApply,
        priority: this.priorityLevel
      }

      let url = `prep-app/announcement-bar`;

      try {
        this.isLoading = true;
        if (!this.announcementDetail) {
          await ApiService.post(url, params);
        } else {
          url = `${url}/${this.announcementDetail.id}`
          await ApiService.put(url, params);
        }

        this.getAnnouncement();

        this.sttComplete = 1;

        this.openCreatModal = false;
      } catch (e) {
        console.log(e);
        this.sttComplete = 2;
      } finally {
        this.isLoading = false;
      }
    },
    async deleteAnnouncement(id) {
      let c = new Promise(resolve => this.cfmDel = resolve);
      await c;
      this.cfmDel = null;

      try {
        this.isLoading = true;
        let url = `prep-app/announcement-bar/${id}`;
        await ApiService.delete(url);

        this.getAnnouncement();

        this.sttComplete = 1;
      } catch (e) {
        console.log(e);
        this.sttComplete = 2;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style lang="css" scoped>
.table-list-announ:nth-child(odd) {
  background: #fefefe;
}

.course-pop {
  display: none;
}

.group-course-pop:hover .course-pop {
  display: block;
}
</style>
